.section.features{
    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 35px;
        max-width: 1150px;
        width: 100%;
        margin: auto;
    }
    .card.groupbuy{
        img{ max-width: 81px; }
    }
}
@media(max-width: 845px){
    .section.features .grid{
        padding-left: 20px;
        padding-right: 20px;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
}