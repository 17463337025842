.card{
    background-image: linear-gradient(180deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.10) 100%);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.10);
    border-radius: 20px;
    color: #FFFFFF;
    padding: 20px;
    text-align: center;
    transition: transform 200ms ease;
    min-height: 255px;
    img.icon{
        margin-bottom: 10px;
        height: 50px;
    }
    h3{
        font-weight: 800;
        font-size: 22px;
        color: #FFFFFF;
        text-align: center;
        // 5
        margin-bottom: 13px;
    }
    p{
        // opacity: 0.7;
        opacity: 1;
        font-weight: 600;
        font-size: 16px;
        color: #ABB0B2;
        line-height: 20px;
        a{
            // opacity: 1;
            color: #65E5FF;
            text-decoration: none;
            font-weight: 800;
        }
    }
    &:hover{
        transform: scale(1.025);
    }
}
@media(max-width: 845px){
    .card{
        min-height: auto;
        h3{
            font-size: 20px;
            margin-bottom: 12px;
        }
    }
}