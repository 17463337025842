.section.faq{
    .cards{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 35px;
        max-width: 1150px;
        width: 100%;
        margin: auto;
        .card{
            padding-left: 15px;
            padding-right: 15px;
            img.icon{
                margin-bottom: 10px;
                height: 60px;
            }
            h3{
                font-weight: 700;
                font-size: 22px;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 13px;
            }
            p{
                // opacity: 0.7;
                font-weight: 600;
                font-size: 16px;
                color: #ACB0B3;
                line-height: 20px;
            }
            &.add{
                img{
                    position: relative !important;
                    margin-top: 5px;
                    height: 70px !important;
                }
            }
            &.tag{
                img{
                    margin-bottom: 10px !important;
                    margin-top: 15px;
                }
            }
            &.master{
                img{
                    margin-bottom: 10px !important;
                    margin-top: 15px;
                }
            }
        }
    }
}

@media(max-width: 845px){
    .section.faq .cards{
        padding-left: 20px;
        padding-right: 20px;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
}