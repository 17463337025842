nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,.10);
    height: 70px;
    backdrop-filter: blur(10px);
    background-color: #032B37aa;
    z-index: 1000;
    transition: background-color 300ms ease;
    button.menu{
        display: none;
    }
    .logo-icon{
        position: absolute;
        left: 30px;
        top: 15px;
        // width: 53px;
        height: 38px;
    }
    ul.links{
        // position: absolute;
        width: 100%;
        left: 50%;
        // transform: translateX(-50%);
        padding-top: 27px;

        top: 27px;
        text-align: center;
        li{
            display: inline-block;
            a{
                display: inline-block;
                margin-left: 20px;
                margin-right: 20px;
                font-weight: 800;
                text-decoration: none;
                font-style: italic;
                color: #fff;
                text-transform: uppercase;
                opacity: .8;
                transition: transform 200ms ease, opacity 200ms ease;
                &:hover{
                    transform: translateY(-2px);
                    opacity: 1;
                }
                &:active{
                    transform: translateY(1px);
                }
            }
        }
    }
    a.btn{
        position: absolute;
        right: 30px;
        top: 16px;
        display: inline-block;
        text-decoration: none;
        font-style: italic;
        text-transform: uppercase;
        background-image: linear-gradient(0deg, #008FFF 0%, #00C0FF 100%);
        border-radius: 5px;
        font-weight: 800;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        padding: 11px;
        transition: transform 200ms ease;
        &:hover{
            transform: scale(1.05);
        }
        &:active{
            transform: scale(1);
        }
    }
}

@media(max-width: 845px){
    nav{
        background-color: rgba(0,0,0,.5);
        height: 100vh;
        &.close{
            background-color: #032B37aa;
            height: 50px;
            ul.links{
                display: none;
            }
        }
        .logo-icon{
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            height: 30px;
        }
        ul.links{
            display: block;
            margin-top: 50px;
            padding-top: 0;
            li{
                display: block;
                border-bottom: 1px solid rgba(255,255,255,.05);
                &:first-child{
                    // a{
                        border-top: 1px solid rgba(255,255,255,.05);
                    // }
                }
                a{
                    display: block;
                    width: 100%;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    text-align: center;
                    margin-left: 0;
                    
                }
            }
        }
        a.btn{
            background-image: url(./icons/dash.svg);
            background-position: center;
            background-repeat: no-repeat;
            height: 50px;
            width: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-indent: 150%;
            padding: 0;
            top: 0;
            right: 0;
        }
        button.menu{
            position: absolute;
            display: inline-block;
            background-color: transparent;
            border: 0;
            background-repeat: no-repeat;
            background-position: center;
            left: 0;
            top: 0;
            width: 50px;
            height: 50px;
            background-image: url(./icons/menu.svg);
            &.close{
                background-image: url(./icons/menu-close.svg);
            }
        }
    }
}