footer {
  height: 241px;
  background-image: linear-gradient(180deg, #2a3137 0%, #141b21 100%);
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  .logo-icon {
    position: absolute;
    top: 105px;
    width: 53px;
  }
  .socials {
    position: absolute;
    right: 0;
    top: 105px;
    .social-icon {
      margin-left: 10px;
      img {
        transition: transform 200ms ease;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .copyright {
    opacity: 0.5;
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    padding-top: 118px;
  }
  .contain {
    max-width: 1150px;
    width: 100%;
    margin: auto;
    position: relative;
  }
}
@media (max-width: 845px) {
  footer {
    height: 241px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    .logo-icon {
      // top: auto;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      right: auto !important;
    }
    .socials {
      width: 100%;
      text-align: center;
      .social-icon:first-child {
        margin-left: 0;
      }
    }
    .copyright {
      position: absolute;
      top: 161px;
      width: 100%;
      padding: 0 !important;
    }
  }
}
