.section{
    padding-top: 105px;
    padding-bottom: 105px;
    background-image: linear-gradient(180deg, #2A3137 0%, #141B21 100%);
    background-size: cover;
    background-repeat: no-repeat;

}
@media(max-width: 845px){
    .section{
        padding-top: 50px;
        padding-bottom: 50px;
    }
}