.section.contact{
    background-image: url(./bg.jpg);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    .contact-box{
        padding: 20px;
        max-width: 600px;
        margin: auto;
        border-radius: 20px;
        background-image: url(./contact-box-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.50);
        .row{
            display: block;
            margin-top: 20px;
            text-align: center;
        }
        label{
            display: inline-block;
            width: 50%;
            &:first-child{
                padding-right: 10px;
            }
            &#textarea{
                display: block;
                width: 100%;
            }
            span{
                display: block;
                opacity: 0.5;
                font-weight: 700;
                font-size: 12px;
                color: #FFFFFF;
                text-transform: uppercase;
                margin-bottom: 6px;
                margin-top: 10px;
            }
            input{
                display: inline-block;
                padding: 10px 7px;
                width: 100%;
                background: rgba(255,255,255,0.10);
                border-radius: 5px;
                font-weight: 600;
                border: 0;
                font-size: 16px;
                color: #FFFFFF;
                outline: none;
                
            }
            textarea{
                display: block;
                padding: 10px 7px;
                width: 100%;
                background: rgba(255,255,255,0.10);
                border-radius: 5px;
                font-weight: 600;
                border: 0;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                outline: none;
                min-width: 100%;
                max-width: 100%;
                min-height: 200px;
                resize: none;
            }
        }
        .btn{
            background-image: linear-gradient(0deg, #008FFF 0%, #00C0FF 100%);
            box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);
            border-radius: 5px;
            font-weight: 900;
            font-style: italic;
            font-size: 20px;
            line-height: 18px;
            color: #FFFFFF;
            border: 0;
            text-transform: uppercase;
            padding: 12px 25px;
            cursor: pointer;
            transition: transform 200ms ease;
            &.disabled{
                background-image: none;
                background-color: #505050;
                color: #afafaf;
                cursor: not-allowed;
            }
            &:hover{
                transform: scale(1.05);
            }
            &:active{
                transform: scale(1);
            }
        }
    }
}
@media(max-width: 845px){
    .section.contact{
        padding-left: 20px;
        padding-right: 20px;
        label{
            &:first-child{
                padding-right: 0px !important;
            }
            display: block !important;
            width: 100% !important;
            input{
                width: 100%;
            }
        }
        .btn{
            width: 100%;
        }
    }
}