.section.success{
    height: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: linear-gradient(180deg, #032B37 0%, #021524 54%, #0399B1 100%, #0399B1 100%);
    // background-size: 150% 130%;
    background-image: url(./bg.png);
    background-size: cover;
    background-position: center;
    position: relative;
    section{
        position: relative;
        z-index: 5;
        .card{
            max-width: 687px;
            width: 100%;
            padding: 44px 52px;
            // background-image: none;
                background-image: radial-gradient(50% 126%, rgba(53, 62, 63, 0.8) 0%, rgba(28, 43, 45, 0.8) 100%);
            box-shadow: 0 20px 30px 0 rgba(0,0,0,0.75);
            border-radius: 20px;
            backdrop-filter: blur(50px);
            // &::after{
            //     content: '';
            //     height: 100%;
            //     width: 100%;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     border-radius: 20px;
            //     opacity: .8;
            //     z-index: 1;
            //     background-image: radial-gradient(50% 126%, #353E3F 0%, #1C2B2D 100%);
            // }
            img.icon{
                height: 60px;
                margin-bottom: 9px;
                position: relative;
                z-index: 2;
            }
            h3{
                font-weight: 800;
                font-style: italic;
                text-transform: uppercase;
                font-size: 34px;
                margin-bottom: 18px;
                position: relative;
                z-index: 2;
            }
            p{
                font-size: 20px;
                line-height: 25px;
                position: relative;
                z-index: 2;
            }
        }
    }
    .contain{
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        max-width: 1220px;
        transform: translateX(-50%);
        overflow-y: hidden;
        .left-phones{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 200px;
            height: 4261px;
            background-image: url(./left-phones.png);
            background-position: left 4261px;
            background-repeat: repeat-y;
            background-size: 200px auto;
        }
        .right-phones{
            position: absolute;
            right: 0;
            top: 0;
            width: 200px;
            height: 4261px;
            background-image: url(./right-phones.png);
            background-size: 200px auto;
            // background-position: right;
            background-repeat: repeat-y;
        }
    }
}

@media(max-width: 845px){
    .section.success{
        height: 680px;
        padding-left: 20px;
        padding-right: 20px;
        .right-phones{
            display: none;
        }
        .left-phones{
            left: 50% !important;
            transform: translateX(-50%);
        }
        .card{
            padding: 20px !important;
            img.icon{
                height: 34px !important;
            }
            h3{
                font-size: 24px !important;
            }
            p{
                font-size: 16px !important;
            }
        }
    }
}