header {
  height: 100vh;
  background-color: #032b37;
  background-image: url(bg.png);
  background-size: cover;
  // background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center calc(100% + 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  section {
    text-align: center;
    img.logo {
      max-width: 300px;
      width: 100%;
      margin-bottom: 20px;
    }
    p {
      font-weight: 800;
      font-size: 28px;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin-bottom: 20px;
      transform: skewX(-7deg);
    }
    button.btn {
      display: inline-block;
      text-decoration: none;
      font-style: italic;
      text-transform: uppercase;
      background-image: linear-gradient(0deg, #008fff 0%, #00c0ff 100%);
      border-radius: 5px;
      font-weight: 900;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      padding: 11px 20px;
      line-height: 18px;
      outline: none;
      border: 0;
      cursor: pointer;
      transition: transform 200ms ease;
      &.soldOut {
        background-image: none;
        background-color: #505050;
        color: #afafaf;
        cursor: initial;
      }
      &:not(.soldOut) {
        &:hover {
          transform: scale(1.05);
        }
        &:active {
          transform: scale(1);
        }
      }
    }
  }
}

@media (max-width: 845px) {
  header {
    padding: 20px;
    // height: 558px;
    background-position: 10% calc(100% + 20px);
    section {
      p {
        font-weight: 800;
      }
    }
  }
}
@media (min-width: 1920px) {
  header {
    background-position: center calc(0% - 200px);
  }
}
@media (min-width: 2500px) {
  header {
    background-position: center calc(0% - 400px);
  }
}
