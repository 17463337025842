.header-section{
    padding-bottom: 105px;
    h2{
        font-weight: 900;
        font-size: 34px;
        font-style: italic;
        color: #FFFFFF;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    p{
        opacity: 0.8;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
    }
}
@media(max-width: 845px){
    .header-section{
        padding-bottom: 20px;
        h2{
            font-size: 24px;
            margin-bottom: 8px;
        }
        p{
            font-size: 14px;
        }
    }
}