@import './vendor/reset';
@import './vendor/normalize';

@import url('https://fonts.googleapis.com/css?family=Muli:400,500,600,700,800,800i,900&display=swap');

*{
    box-sizing: border-box;
}

body{
    font-family: 'Muli', sans-serif;
    background-color: #141B21;
}